import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '40vh',
    minWidth: '40vw'
  },
  dialogTitle: {
    backgroundColor:  "#A60000", // Colore di sfondo rosso
    color: 'white', // Colore del testo bianco
  },
  dialogContent: {
    backgroundColor: '#ffcccc', // Colore di sfondo rosso chiaro
  },
  dialogActions: {
    
  },
  messageText: {
    color: 'black', // Colore del testo nero
    marginTop: theme.spacing(4),
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  alertMessage: {
    color: 'black', // Colore del testo nero
    marginTop: theme.spacing(6), // Margine superiore per posizionare più in basso
    fontSize: '1.2rem',
  },
}));

const DialogRecensioni = ({ open, toggleOpen, dati }) => {
  const classes = useStyles();
  const [countdown, setCountdown] = useState(15);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (open) {
      setCountdown(15);
      setButtonDisabled(true);
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setButtonDisabled(false);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [open]);


  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      toggleOpen();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        ATTENZIONE - {dati.title} ⚠️
      </DialogTitle>
      <DialogContent >
      <DialogContentText className={classes.messageText} id="message">
        <span className={classes.icon}>ℹ️</span> {dati.messaggio}
        </DialogContentText>
        <DialogContentText className={classes.alertMessage} id="tip">
          <span className={classes.icon}>💡</span> {dati.tip}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={toggleOpen} color="primary" disabled={buttonDisabled}>
          Chiudi {buttonDisabled && `(${countdown})`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogRecensioni;