import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: theme.spacing(4),
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  tableCell: {
    fontWeight: 'bold',
  },
  button: {
    margin: theme.spacing(1),
  },
  error: {
    color: 'red',
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  noData: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));

const Chiamate = () => {
  const classes = useStyles();
  const [chiamate, setChiamate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChiamate = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/contatti_social/chiamate_da_fare`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `JWT ${localStorage.getItem('token')}`
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setChiamate(data.results);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchChiamate();
  }, []);

  const handleChiamataEffettuata = (index) => {
    // Logica per segnare la chiamata come effettuata
    console.log(`Chiamata effettuata per: ${chiamate[index].cognome_nome}`);
  };

  const handleChiamataNonRisposta = (index) => {
    // Logica per segnare la chiamata come non risposta
    console.log(`Chiamata non risposta per: ${chiamate[index].cognome_nome}`);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return (
        <Typography variant="h6" className={classes.error}>
          Error: {error}
        </Typography>
      );
  }

  if (chiamate.length === 0) {
        return (
          <Typography variant="h6" className={classes.noData}>
            Nessuna chiamata.
          </Typography>
        );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Chiamate
      </Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="chiamate table">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell className={classes.tableCell}>Paziente</TableCell>
              <TableCell className={classes.tableCell}>Telefono</TableCell>
              <TableCell className={classes.tableCell}>Motivo</TableCell>
              <TableCell className={classes.tableCell}>Azione</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chiamate.map((chiamata, index) => (
              <TableRow key={index}>
                <TableCell>{chiamata.cognome_nome}</TableCell>
                <TableCell>{chiamata.telefono}</TableCell>
                <TableCell>{chiamata.motivo}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => handleChiamataEffettuata(index)}
                  >
                    Effettuata
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => handleChiamataNonRisposta(index)}
                  >
                    Non Risposta
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Chiamate;