import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import fromCDN from "from-cdn"
import $ from 'jquery'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';

import ClearIcon from '@material-ui/icons/Clear';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';


import ScheduleIcon from '@material-ui/icons/Schedule';
import ScheduleTwoToneIcon from '@material-ui/icons/ScheduleTwoTone';

import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Box from '@material-ui/core/Box'
import { withSnackbar } from 'notistack';
import withConfirm from 'material-ui-confirm'
import api from "../api_calls/api";


function getIcon(stato) {
    let iconaStato = "";
    try {
      switch (stato) {
        case "Iniziale":
          iconaStato = document.getElementById("iniziale_icon").innerHTML + ' ';
          break;
        case "Confermato":
          iconaStato = document.getElementById("confermato_icon").innerHTML + ' ';
          break;
        case "Annullato":
          iconaStato = document.getElementById("annullato_icon").innerHTML + ' ';
          break;
        case "Mancata esecuzione":
          iconaStato = document.getElementById("annullato_icon").innerHTML + ' ';
          break;
        case "Effettuato":
          iconaStato = document.getElementById("effettuato_icon").innerHTML + ' ';
          break;
        default:
          iconaStato = '';
      }
    } catch (e) {;}
    return iconaStato;
  }

function dateToString(d) {
  return d.getFullYear() + ('0' + (d.getMonth()+1)).slice(-2) + ('0' + d.getDate()).slice(-2);
}

class Scheduler extends Component {

  schedulerMedici = [];

// INUTILIZZATO?  sede_corrente_id = localStorage.getItem("sede_corrente_id");
  constructor(props) {
    super(props);
    this.container = React.createRef();
    var operatore = api.getOperatore();
    var dict_sedi = {};
    for (var i = 0, len = operatore.sedi.length; i < len; i++) {
      dict_sedi[operatore.sedi[i].id] = {}
    }
    var dict_sedi_medici_attivi_per_sede = {};
    for (i = 0, len = operatore.sedi.length; i < len; i++) {
      dict_sedi_medici_attivi_per_sede[operatore.sedi[i].id] = []
    }
    this.state = {
      operatore: operatore,
      isTaskOn: true,
      isAnnullatiOn: false,
      showMessageSede: true,
      sedi: [],
      medici: [],
      tuttiIMedici: [],
      medici_disponibili_per_data_e_sede: dict_sedi,
      medici_attivi_per_sede: dict_sedi_medici_attivi_per_sede,
      sede_corrente_id: localStorage.getItem("sede_corrente_id"),
      sede_corrente_nome_breve: localStorage.getItem("sede_corrente_nome_breve"),
      sede_corrente_nome: localStorage.getItem("sede_corrente_nome")
    };
    this.toggleTask = this.toggleTask.bind(this);
    this.toggleAnnullati = this.toggleAnnullati.bind(this);
    this.rotateSede = this.rotateSede.bind(this);
    this.onLoadEnd = this.onLoadEnd.bind(this);
    this.filter_appointments = this.filter_appointments.bind(this);
    this.ready = fromCDN([
      `${process.env.REACT_APP_API_URL}/static/css/dhtmlxscheduler_material.css`
    ])
      .then(_ => fromCDN([
        `${process.env.REACT_APP_API_URL}/static/css/dhtmlx_custom.css`
      ]))
      .then(_ => fromCDN([`${process.env.REACT_APP_API_URL}/static/js/dhtmlxscheduler.js`]))
      .then(_ => fromCDN([`${process.env.REACT_APP_API_URL}/static/js/dhtmlxscheduler_units.js`]))
      .then(_ => fromCDN([`${process.env.REACT_APP_API_URL}/static/js/dhtmlxscheduler_quick_info.js`]))
      .then(_ => fromCDN([`${process.env.REACT_APP_API_URL}/static/js/dhtmlxscheduler_limit.js`]))
      .then(_ => fromCDN([`${process.env.REACT_APP_API_URL}/static/js/dhtmlxscheduler_minical.js`]))
      .then(_ => fromCDN([`${process.env.REACT_APP_API_URL}/static/js/locale_it.js`]))

  }

  filter_appointments(id, event) {
    if (this.state.sedi.length===0 || event["!nativeeditor_status"]==="deleted") {
      return false;
    }
    // se l'evento non ha database_id è in drag e quindi lo voglio vedere
    if (event.database_id === undefined) return true;  // è troppo generico ?
    if (this.state.isTaskOn) {
      if (this.state.isAnnullatiOn) {
        return parseInt(this.state.sede_corrente_id) === parseInt(event.sede);
      } else {
        return parseInt(this.state.sede_corrente_id) === parseInt(event.sede) && event.stato !== 'Annullato';
      }
    } else {
      if (this.state.isAnnullatiOn) {
        return event.oggetto !== "task" && parseInt(this.state.sede_corrente_id) === parseInt(event.sede);
      } else {
        return event.oggetto !== "task" && parseInt(this.state.sede_corrente_id) === parseInt(event.sede) &&
          event.stato !== 'Annullato';
      }
    }
  }

  onLoadEnd() {
    const medici_disponibili_per_data_sede_corrente = scheduler.serverList("medici_disponibili_per_data")[0];
    const sede_corrente_id = parseInt(localStorage.getItem("sede_corrente_id"));
    var medici_disponibili_per_data_e_sede = this.state.medici_disponibili_per_data_e_sede;
    medici_disponibili_per_data_e_sede[sede_corrente_id] = {...medici_disponibili_per_data_e_sede[sede_corrente_id], ...medici_disponibili_per_data_sede_corrente};
    var medici_attivi_per_sede = this.state.medici_attivi_per_sede;
    medici_attivi_per_sede[sede_corrente_id] = scheduler.serverList("medici_attivi_per_sede");
    this.setState(state => ({
      medici_disponibili_per_data_e_sede: medici_disponibili_per_data_e_sede,
      medici_attivi_per_sede: medici_attivi_per_sede
    }));

    /*    console.log("onLoadEnd sede_corrente_id " + sede_corrente_id);
        console.log("onLoadEnd this.state.medici_attivi_per_sede n. " + sede_corrente_id + " length " + this.state.medici_attivi_per_sede[sede_corrente_id].length);
        for( var i = 0; i < this.state.medici_attivi_per_sede[sede_corrente_id].length; i++) {
          console.log(JSON.stringify(this.state.medici_attivi_per_sede[sede_corrente_id][i]));
        } */
    const medici = scheduler.serverList("medici");

    let tuttiIMedici = this.state.tuttiIMedici;
    const indisponibilita = scheduler.serverList("indisponibilita");
    const sedi = scheduler.serverList("sedi");

    console.log("onLoadEnd localStorage.getItem(\"sede_corrente_id\") " + localStorage.getItem("sede_corrente_id"))
    let nome_medico_finto_per_task = ''

    const isTaskOn = this.state.isTaskOn
    // se ho il task lo rimuovo temporaneamente per far sì che sia sempre in fondo
    for (var i = 0; i < medici.length; i++) {
      if (medici[i].key === "0") {
        nome_medico_finto_per_task = medici[i].label
        break
      } else {
        // mi salvo nella lista completa eventuali medici aggiunti
        let daAggiungere = true;
        for (var j = 0; j < tuttiIMedici.length; j++) {
          if (tuttiIMedici[j].key === medici[i].key) {
            daAggiungere = false;
          }
        }
        if (daAggiungere) {
          tuttiIMedici.push({"key": medici[i].key, "label": medici[i].label});
        }
      }
    }
    if (isTaskOn) {
      for (var i = 0; medici && i < medici.length; i++) {
        if (medici[i].key === "0") {
          medici.splice(i, 1)
        }
      }
    }

    for (var j = 0; j < tuttiIMedici.length; j++) {
      // se nell'intervallo di date attuali manca un medico che era presente in un altrointervallo di date lo aggiungo
      // medici è la lista che governa le colonne della vista D
      let daAggiungere = true;
      for (var i = 0; i < medici.length; i++) {
        if (tuttiIMedici[j].key === medici[i].key) {
          daAggiungere = false;
        }
      }
      if (daAggiungere) {
        medici.push({"key": tuttiIMedici[j].key, "label": tuttiIMedici[j].label});
      }
    }
    let idTuttiIMedici = [];
    for (var j = 0; j < tuttiIMedici.length; j++) {
      idTuttiIMedici.push(parseInt(tuttiIMedici[j].key));
    }
    // se ho il task l'ho rimosso temporaneamente; adesso lo rimetto in fondo
    if (isTaskOn && this.state.nome_medico_finto_per_task !== undefined) {
      medici.push({"key": "0", "label": this.state.nome_medico_finto_per_task});
    }

    indisponibilita.forEach(function myFunction(value, index, array) {
      if (value.sede_id === sede_corrente_id) {
        scheduler.addMarkedTimespan({
          days: new Date(value.date_year, value.date_month - 1, value.date_day),
          zones: [value.start_time, value.end_time],
          css: "gray_section",
          sections: {dottori: value.medico_id}
        });
      }
    });
    if (this.state.showMessageSede && sedi.length > 1) {
      this.props.enqueueSnackbar("DATI DI " + this.state.sede_corrente_nome, {
        variant: 'success',
        autoHideDuration: 3000
      });
    }
    this.setState(state => ({
      showMessageSede: false,
      medici: medici,
      tuttiIMedici: tuttiIMedici,
      sedi: sedi,
      nome_medico_finto_per_task: nome_medico_finto_per_task
    }));
    if (scheduler.getState().mode === "dottori") {
      var medici_per_data = this.aggiornaListaMediciD(dateToString(scheduler.getState().date));
      scheduler.updateCollection(medici_per_data);
    }
    let evt_id = localStorage.getItem("evt_id");
    if (evt_id) {
      localStorage.removeItem('evt_id');
      scheduler.showLightbox('appuntamento' + evt_id);
    }
  }

  getIndexSede(sede_id) {
    /*
      ciclo su sedi per trovare l'indice' nell'array corrispondente all'id della sede
   */
    let index_sede_corrente = -1;
    for( var i = 0; i < this.state.sedi.length; i++) {
      if (this.state.sedi[i].key === sede_id) {
        index_sede_corrente = i;
        break
      }
    }
    return index_sede_corrente;
  }

  getIndexSedeCorrente() {
    /*
      ciclo su sedi per trovare l'indice' nell'array corrispondente all'id della sede
   */
    return this.getIndexSede(this.state.sede_corrente_id);
  }

  rotateSede() {
    let index_sede_corrente = this.getIndexSedeCorrente();
    var new_index_sede_corrente = (index_sede_corrente + 1) % this.state.sedi.length;

    scheduler.deleteMarkedTimespan();
    const sede_corrente_id = this.state.sedi[new_index_sede_corrente].key;
    const sede_corrente_nome_breve = this.state.sedi[new_index_sede_corrente].label;
    const sede_corrente_nome = this.state.sedi[new_index_sede_corrente].name;
// INUTILIZZATO?    this.sede_corrente_id = sede_corrente_id;
    this.setState({
      sede_corrente_id: sede_corrente_id,
      sede_corrente_nome_breve: sede_corrente_nome_breve,
      sede_corrente_nome: sede_corrente_nome
    }, () => {
      const url_da_eseguire = `${process.env.REACT_APP_API_URL}/dc/set_sede?sede_id=`+sede_corrente_id;
      api.call_get(url_da_eseguire, (result) => {
        this.props.enqueueSnackbar("DATI DI " + sede_corrente_nome, {variant:  'success', autoHideDuration: 6000});
        localStorage.setItem('sede_corrente_id', sede_corrente_id);
        localStorage.setItem('sede_corrente_nome_breve', sede_corrente_nome_breve);
        localStorage.setItem('sede_corrente_nome', sede_corrente_nome);
        scheduler.clearAll();
        scheduler.setCurrentView();
      });
    });
  }

  cambiaSede(evt) {
    const sede_corrente_id = evt.target.value;
    let sede_corrente_nome_breve = "";
    let sede_corrente_nome = "";
    for (var k = 0; k < this.state.sedi.length; ++k) {
      if(this.state.sedi[k].key === sede_corrente_id) {
        sede_corrente_nome_breve = this.state.sedi[k].label;
        sede_corrente_nome = this.state.sedi[k].name;
        break;
      }
    }
    this.setState({
      sede_corrente_id: sede_corrente_id,
      sede_corrente_nome_breve: sede_corrente_nome_breve,
      sede_corrente_nome: sede_corrente_nome
    }, () => {
      const url_da_eseguire = `${process.env.REACT_APP_API_URL}/dc/set_sede?sede_id=`+sede_corrente_id;
      api.call_get(url_da_eseguire, (result) => {
        this.props.enqueueSnackbar("DATI DI " + sede_corrente_nome, {variant:  'success', autoHideDuration: 6000});
        localStorage.setItem('sede_corrente_id', sede_corrente_id);
        localStorage.setItem('sede_corrente_nome_breve', sede_corrente_nome_breve);
        localStorage.setItem('sede_corrente_nome', sede_corrente_nome);
        scheduler.clearAll();
        scheduler.setCurrentView();
      });
    });
  }

  toggleTask() {
    const isTaskOn = !this.state.isTaskOn
    let medici = this.state.medici
    if(isTaskOn) {
        medici.push({"key": "0", "label": this.state.nome_medico_finto_per_task});
    } else {
      for( var i = 0; medici && i < medici.length; i++){
        if ( medici[i].key === "0") {
          medici.splice(i, 1)
        }
      }
    }
    this.setState({
      isTaskOn: isTaskOn,
      medici: medici
    });
  }

  toggleAnnullati() {
    const isAnnullatiOn = !this.state.isAnnullatiOn
    this.setState({
      isAnnullatiOn: isAnnullatiOn
    });
  }

  showMiniCal() {
    if (window.scheduler){
      if (window.scheduler.isCalendarVisible())
        window.scheduler.destroyCalendar();
      else
        window.scheduler.renderCalendar({
          position:"dhx_minical_icon",
          date:window.scheduler.getState().date,
          navigation:true,
          handler:function(date,calendar){
            window.scheduler.setCurrentView(date);
            window.scheduler.destroyCalendar()
          }
        });
    }
  }

  updateEventColorFromType(event) {
    let rosso = "FF0000";
    let rosa = "EA899A";
    let rosa2 = "FFC0CB";
    let giallo = "F5D033";
    let arancione = "FFA540";
    let verde = "00BB2D";
    let azzurro = "00BCD4";
    let viola = "9C27B0";
    let grigio = "738277";
    switch (event.tipo) {
      case "PV":
        event.color = arancione;
        break;
      case "VS":
        event.color = arancione;
        break;
      case "NC":
        event.color = arancione;
        break;
      case "PR":
        event.color = arancione;
        break;
      case "CO" :
        event.color = rosa;
        break;
      case "RI" :
        event.color = giallo;
        break;
      case "TO" :
        event.color = rosa2;
        break;
      case "TA":
        event.color = verde;
        break;
      case "TI":
        event.color = verde;
        break;
      case "ID":
        event.color = azzurro;
        break;
      case "TC":
        event.color = azzurro;
        break;
      case "GA":
        event.color = rosso;
        break;
      case "RG":
        event.color = viola;
        break;
      case "CE":
        event.color = "";
        break;
      case "UR":
        event.color = "";
        break;
    }
    if (event.motivo_annullamento === 'NP' || event.motivo_mancata_esecuzione === 'PS') {
      event.color = rosso;
    }
  }

  aggiornaListaMediciD(data) {
    let medici = this.schedulerMedici; // this.state.medici;
    var medici_disponibili_per_data_e_sede = this.state.medici_disponibili_per_data_e_sede;
    var medici_disponibili_per_questa_data_e_sede = medici_disponibili_per_data_e_sede[this.state.sede_corrente_id][data];
    if (medici_disponibili_per_questa_data_e_sede !== undefined) {
      for (var i = 0; i < medici_disponibili_per_questa_data_e_sede.length; i++) {
        var aggiungi = true;
        for (var j = 0; j < medici.length; j++) {
          if (medici_disponibili_per_questa_data_e_sede[i].key === medici[j].key) aggiungi = false;
        }
        if (aggiungi) {
          console.log("aggiornaListaMediciD aggiungo " + JSON.stringify(medici_disponibili_per_questa_data_e_sede[i]));
          medici.push(medici_disponibili_per_questa_data_e_sede[i]);
        }
      }
      var da_rimuovere = [];
      for (j = 0; j < medici.length; j++) {
        var bool_da_rimuovere = true;
        for (i = 0; i < medici_disponibili_per_questa_data_e_sede.length; i++) {
          if (medici_disponibili_per_questa_data_e_sede[i].key === medici[j].key) bool_da_rimuovere = false;
        }
        if (bool_da_rimuovere) da_rimuovere.push(j);
      }
      da_rimuovere.sort(function (a, b) {
        return b - a
      });
      // ordinato discendente per poter eliminare gli elementi
      for (i = 0; i < da_rimuovere.length; i++) {
        medici.splice(da_rimuovere[i], 1);
      }
      this.setState({medici: medici});
    }
    return medici;
  }

  templatesEventClass(start, end, event) {
    if (event.oggetto === 'task') {
      event.color = "9E9E9E";
    } else {
      this.updateEventColorFromType(event);
    }
    var evidenziaPagamento = "";
    if (event.saldi_da_pagare > 500) {
      evidenziaPagamento = " riscuotere "
    } else if (event.saldi_da_pagare > 0) {
      evidenziaPagamento = " riscuoterepoco "
    }
    if (event.color) {
      return evidenziaPagamento + " event_color_" + event.color;
    } else {
      return evidenziaPagamento
    }
  }

  reInitScheduler(scheduler) {
    var thisReact = this;  // SERVE PER USARLO DENTRO GLI EVENTI
    if (this.props.evt === undefined) {
      // sto chiudendo la form e quindi non voglio salvare con addEvent
      if (this.props.evt_prima_delle_modifiche !== undefined) {
        let evento_nello_scheduler = scheduler.getEvent(this.props.evt_prima_delle_modifiche.id);
        for(var k in this.props.evt_prima_delle_modifiche) evento_nello_scheduler[k]=this.props.evt_prima_delle_modifiche[k];
        scheduler.updateView();
      }
      if (this.props.schedulerState === undefined) {
        scheduler.init(this.container.current, new Date(), "week");
      } else {
        scheduler.init(this.container.current, this.props.schedulerState.date, this.props.schedulerState.mode);
      }
    } else {
      if (this.props.schedulerState === undefined) {
        scheduler.init(this.container.current, this.props.evt.start_date, "week");
      } else {
        scheduler.init(this.container.current, this.props.schedulerState.date, this.props.schedulerState.mode);
      }
      let ev = {};

      if (this.props.evt.database_id === 0) {
        for (var k in this.props.evt) ev[k] = this.props.evt[k]
      } else {
        ev = scheduler.getEvent(this.props.evt.id);
        for (var k in this.props.evt) ev[k] = this.props.evt[k];
        // scheduler vuole l'array jsonato
        scheduler.getEvent(this.props.evt.id).json_attivita_appuntamento = JSON.stringify(scheduler.getEvent(this.props.evt.id).json_attivita_appuntamento)
      }
      scheduler.addEvent(ev)
      // https://docs.dhtmlx.com/scheduler/server_integration.html#triggeringdatasavingfromscript
      scheduler.templates['event_class'] = function (start, end, event) {
        return thisReact.templatesEventClass(start, end, event);
      }
    }
    if (this.props.evt_chiuso && this.props.evt_chiuso.database_id === 0) {
      scheduler.deleteEvent(this.props.evt_chiuso.id);
      if (this.props.evt_chiuso.dhtmlx_id !== undefined) scheduler.deleteEvent(this.props.evt_chiuso.dhtmlx_id)
    }
    scheduler.filter_week = this.filter_appointments;
    scheduler.filter_day = this.filter_appointments;
    scheduler.filter_dottori = this.filter_appointments;

    $("#tab_custom").append($("#tab_ann"));
    $("#tab_custom").append($("#tab_task"));
    $("#tab_custom").append($("#tab_sedi"));
    scheduler.showLightbox = (id) => {
      const evt = scheduler.getEvent(id);
      const sede_corrente_id = parseInt(localStorage.getItem("sede_corrente_id"));
      if (evt !== undefined) {
        //passa l'evento al parent
        let options_pazienti = [];
        if (evt.oggetto === 'task') {
          options_pazienti = [
            {
              label: evt.nome_paziente,
              value: {
                cartella_clinica: evt.cartella_clinica,
                id: evt.cartella_clinica,
                text: evt.nome_paziente
              }
            }
          ]
        }
/*        console.log("reInitScheduler showLightbox sede_corrente_id " + sede_corrente_id);
        console.log("reInitScheduler showLightbox this.state.medici_attivi_per_sede[sede_corrente_id] length " + this.state.medici_attivi_per_sede[sede_corrente_id].length);
        for( var i = 0; i < this.state.medici_attivi_per_sede[sede_corrente_id].length; i++) {
          console.log(JSON.stringify(this.state.medici_attivi_per_sede[sede_corrente_id][i]));
        }*/
        //this.props.setEvent(evt, this.state.medici_attivi_per_sede[sede_corrente_id], sede_corrente_id, scheduler.getState(), options_pazienti);
        console.log("reInitScheduler " + JSON.stringify(evt));
        this.props.setEvent(evt, this.state.medici, this.state.sede_corrente_id, scheduler.getState(), options_pazienti);
        $("#tab_custom").html("");
      }
      return false
    };
  }

  initScheduler(scheduler) {
    /* globals scheduler */
    scheduler.$ajax._call = function (method, url, postData, async, onLoad, longParams, headers) {
      var scheduler = this._obj;
      var t = new XMLHttpRequest();
      var isQt = (navigator.userAgent.match(/AppleWebKit/) !== null && navigator.userAgent.match(/Qt/) !== null && navigator.userAgent.match(/Safari/) !== null);

      if (!!async) {
        t.onreadystatechange = function () {

          if ((t.readyState == 4) || (isQt && t.readyState == 3)) { // what for long response and status 404?
            if (t.status == 401) window.location.href = "/gui/pazienti/";  // Unauthorized è un trucco mandarlo su pazienti perché se lo rimando su / resta in loop su questa invocazione
            if (t.status == 204) return;
            if (t.status != 200 || t.responseText === "") // PATCH DRF restituisce correttamente 204 No content all'eliminazione
              if (!scheduler.callEvent("onAjaxError", [t])) return;

            window.setTimeout(function () {
              if (typeof (onLoad) == "function") {
                onLoad.apply(window, [{xmlDoc: t, filePath: url}]); // dhtmlx-compat, response.xmlDoc.responseXML/responseText
              }
              if (longParams) {
                if (typeof (longParams.postData) != "undefined") {
                  this.postLong(longParams.url, longParams.postData, onLoad);
                } else {
                  this.getLong(longParams.url, onLoad);
                }
              }
              onLoad = null;
              t = null;
            }, 1);
          }
        };
      }
      if (method == "GET" && !this.cache) {
        url += (url.indexOf("?") >= 0 ? "&" : "?") + "dhxr" + new Date().getTime() + "=1";
      }
      url += (url.indexOf("?") >= 0 ? "&" : "?") + "sede_id=" + localStorage.getItem("sede_corrente_id");
      t.open(method, url, async);
      t.setRequestHeader("Authorization", `JWT ${localStorage.getItem('token')}`)
      if (headers) {
        for (var key in headers)
          t.setRequestHeader(key, headers[key]);
      } else if (method.toUpperCase() == "POST" || method == "PUT" || method == "DELETE") {
        t.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      } else if (method == "GET") {
        postData = null;
      }

      t.setRequestHeader("X-Requested-With", "XMLHttpRequest");

      t.send(postData);

      if (!async) return {xmlDoc: t, filePath: url}; // dhtmlx-compat, response.xmlDoc.responseXML/responseText

    }
    scheduler.config.xml_date = "%Y-%m-%d %H:%i";
    scheduler.config.drag_move = false;
    scheduler.locale.labels.day_tab = "1"
    scheduler.locale.labels.week_tab = "7"
    scheduler.locale.labels.dottori_tab = "D"
    scheduler.locale.labels.dhx_cal_today_button = "!"
    scheduler.config.time_step = 15;
    scheduler.config.details_on_create = true;
    scheduler.config.details_on_dblclick = true;
    scheduler.config.quick_info_detached = true;
    /* email del supporto dhtmlx 2019-09-02 14:29 */
    scheduler.config.fix_tab_position = false;

    //TODO per qualche motivo non riesce a leggere i valori dal file env -- da approfondire
    scheduler.config.first_hour = 8 //parseInt(`${process.env.WORKING_HOURS_START}`);
    scheduler.config.last_hour = 21 //parseInt(`${process.env.WORKING_HOURS_END}`);


    // Axis-y con timestep di 15 minuti    https://docs.dhtmlx.com/scheduler/samples/02_customization/09_timestep.html
    var thisReact = this;  // SERVE PER USARLO DENTRO GLI EVENTI
    var step = 15;
    var format = scheduler.date.date_to_str("%H:%i");
    scheduler.config.hour_size_px = (60 / step) * 22;

		scheduler.templates.event_text=function(start,end,event){
		  if (event.oggetto==='appuntamento') {
		    return getIcon(event.stato) + event.text.substr(0,100);
      }
		  if (event.oggetto==='task') {
		    return event.text + " " + event.testo_libero;
      }
			return "Text:<b> "+event.text+"</b><br>"+"Descr."+event.details;
		};
    scheduler.templates['event_class'] = function (start, end, event) {
      return thisReact.templatesEventClass(start, end, event);
    };
    scheduler.templates.quick_info_title = function(start, end, ev){
      const url_pdf = ` ${process.env.REACT_APP_API_URL}/dc/pdf_dopo_appuntamento/`;
      if (ev.database_id > 0 && ev.oggetto==='appuntamento') {
        if(ev.paziente_id === 0) {
          return getIcon(ev.stato) + ev.nome_paziente;
        } else {
          return getIcon(ev.stato) + '<a href="/gui/pazienti/?pid=' + ev.paziente_id + '">' + ev.nome_paziente + "</a>&nbsp;<a target='_blank' href='" + url_pdf + ev.database_id + ".pdf'>" + document.getElementById("pdf_icon").innerHTML + "</a>" + (ev.telefono_paziente!== undefined ? " "+ev.telefono_paziente : "");
        }
      }
      if (ev.database_id > 0 && ev.oggetto==='task') {
        return '<a href="/gui/pazienti/?pid=' + ev.paziente_id + '">' + ev.nome_paziente + "</a> " + (ev.telefono_paziente!== undefined ? " "+ev.telefono_paziente : "");
      }
      return ev.text.substr(0,100) + (ev.telefono_paziente!== undefined ? " "+ev.telefono_paziente : "");
    };
    scheduler.templates.quick_info_content = function(start, end, ev) {
      if (ev.database_id > 0 && ev.oggetto==='appuntamento') {
        return ev.text.substr(0,300) + "<br>" + ev.testo_libero;
      }
      if (ev.database_id > 0 && ev.oggetto==='task') {
        return ev.text + "<br>" + ev.testo_libero;
      }
      return ev.details || ev.text;
    };
    var timeToStr = scheduler.date.date_to_str("%G:%i");
    scheduler.templates.quick_info_date = function(start, end, ev) {
      return scheduler.templates.day_date(start, end, ev) + ', ' + timeToStr(start) + '-' + timeToStr(end);
    };
    scheduler.templates.day_date = function(date) {
      var formatFunc = scheduler.date.date_to_str("%D %j %M '%y");
      return formatFunc(date);
    };
    scheduler.templates.event_header = function(start,end,ev) {
      return "";
    };
    scheduler.templates.week_date = function(start, end){
      var formatStartFunc = scheduler.date.date_to_str("%j %M");
      var formatEndFunc = scheduler.date.date_to_str("%j %M '%y");
      return formatStartFunc(start)+" &ndash; "+formatEndFunc(scheduler.date.add(end,-1,"day"));
    };
    scheduler.templates.hour_scale = function (date) {
      var html = "";
      for (var i = 0; i < 60 / step; i++) {
        html += "<div style='height:22px;line-height:22px;'>" + format(date) + "</div>";
        date = scheduler.date.add(date, step, "minute");
      }
      return html;
    };
    // Adesso  scheduler.serverList("medici") è vuota perché non ha ancora caricato i dati
    scheduler.createUnitsView({
      name: "dottori",
      property: "medico", //the mapped data property
      list: this.schedulerMedici, // scheduler.serverList("medici"), //[],
      days: 1,
      skip_incorrect: true
    });

    var formatDateUrl = scheduler.date.date_to_str("%Y%m%d");
    scheduler.templates.dottori_scale_text = function(key, label, unit, date) {
      if (parseInt(key) > 0) {
        const url_pdf = ` ${process.env.REACT_APP_API_URL}/dc/pdf_day/`;
        return '<a target="_blank" href="' + url_pdf + formatDateUrl(date) + '/' + thisReact.state.sede_corrente_id + '/' + key + '">' + label + '</a>';
      } else {
        return label;
      }
    };

    scheduler.attachEvent('onClick', function (id) {
      let clicked_event = scheduler.getEvent(id);
      console.log("onClick (initScheduler) " + JSON.stringify(clicked_event));
      const evt_prima_delle_modifiche = {...clicked_event}; // clono l'evento prima che venga modificato nella form
      thisReact.props.setEventPrimaDelleModifiche(evt_prima_delle_modifiche);
      return false;
    });
    scheduler.attachEvent("onViewChange", function(new_mode, new_date) {
      return;
      console.log("onViewChange " + new_mode + " " + new_date);
      let medici = thisReact.state.medici;

      var medici_disponibili_per_data_e_sede = thisReact.state.medici_disponibili_per_data_e_sede;
      var medici_disponibili_per_questa_data_e_sede = medici_disponibili_per_data_e_sede[thisReact.state.sede_corrente_id][dateToString(scheduler.getState().min_date)];
      var ho_modificato_la_lista = false;
      if (medici_disponibili_per_questa_data_e_sede !== undefined) {
        //for ( var i = 0; i < medici.length; i++) medici.splice(0, 1);
        for (var i = 0; i < medici_disponibili_per_questa_data_e_sede.length; i++) {
    //      console.log(JSON.stringify(medici_disponibili_per_questa_data_e_sede[i]));
          var aggiungi = true;
          for( var j = 0; j < medici.length; j++) {
            if (medici_disponibili_per_questa_data_e_sede[i].key === medici[j].key) aggiungi = false;
          }
          if (aggiungi) {
//            console.log('LO STO AGGIUNGENDO');
            ho_modificato_la_lista = true;
            medici.push(medici_disponibili_per_questa_data_e_sede[i]);
          }
        }
//        console.log('INIZIO RIMOZIONE');
        var da_rimuovere = [];
        for( var j = 0; j < medici.length; j++) {
          var bool_da_rimuovere = true;
          for (var i = 0; i < medici_disponibili_per_questa_data_e_sede.length; i++) {
            if (medici_disponibili_per_questa_data_e_sede[i].key === medici[j].key) {
              bool_da_rimuovere = false;
            }
          }
          if (bool_da_rimuovere) da_rimuovere.push(j);
        }
        da_rimuovere.sort(function(a, b){return b-a});
        // ordinato discendente per poter eliminare gli elementi
        for (var i = 0; i < da_rimuovere.length; i++) {
//          console.log("Sto rimuovendo " + JSON.stringify(medici[i]) + " " + da_rimuovere[i]);
          ho_modificato_la_lista = true;
          medici.splice(da_rimuovere[i], 1);
        }
        console.log(JSON.stringify("thisReact.state.medici FINE DI onViewChange medici.length " + medici.length));
        for( var i = 0; i < medici.length; i++) {
          console.log(JSON.stringify(medici[i]));
        }
        console.log("ho_modificato_la_lista: " + ho_modificato_la_lista);
/*        if (ho_modificato_la_lista) {
          thisReact.setState({medici: medici}, () => {
            scheduler.updateCollection("medici", medici);
          });
        }
/*        console.log(JSON.stringify("thisReact.state.medici DOPO L'ALLINEAMENTO "));
        for( var i = 0; i < medici.length; i++) {
          console.log(JSON.stringify(medici[i]));
        }
      scheduler.createUnitsView({
        name: "dottori",
        property: "medico", //the mapped data property
        list: thisReact.state.medici,
        days: 1,
        skip_incorrect: true
      });*/

      }
    });
    scheduler.attachEvent("onBeforeViewChange", function(old_mode,old_date,mode,date) {
      if (!old_date || old_date.valueOf() != date.valueOf() || old_mode !== mode ) {
        // non è il primo caricamento; come faccio a sapere se ho già i dati?
        if (mode==='dottori') {
          if (old_mode!==old_date || mode!==date) {
            var medici = thisReact.aggiornaListaMediciD(formatDateUrl(date));
            scheduler.updateCollection("medici", medici);
          }
        }
      }
      return true;
    });
    scheduler.attachEvent("onEventChanged", function(id,ev){
      thisReact.props.enqueueSnackbar("Evento salvato", {variant:  'success', autoHideDuration: 5000});
    });
    scheduler.attachEvent("onSaveError", function (ids, resp) {
      var message = "Salvataggio: ";
//      console.log("onSaveError ids: " + ids);  // [1567134328203]
      console.log(message);
      console.log(resp);
      console.log(resp.response);
      var parsed_response = JSON.parse(resp.response); // "{"id":1752,"text":"Bionda Gaia - C. Spallanzani -","start_date":"2019-08-26T09:00:00","end_date":"2019-08-26T09:30:00","color":"9E9E9E","medico_coinvolto":null,"operatore":null,"sede":1}

      if (resp.status === 201 || resp.status === 200) {
        if (parsed_response.messaggio_disponibilita) {
          thisReact.props.enqueueSnackbar(parsed_response.messaggio_disponibilita, {variant:  'error', autoHideDuration: 10000});
        }
        if (parsed_response.messaggio_ripetibilita) {
          thisReact.props.enqueueSnackbar(parsed_response.messaggio_ripetibilita, {variant:  'error', autoHideDuration: 10000});
        }
        if (resp.statusText === "Created" && parsed_response.id !== null) {
          let old_event = scheduler.getEvent(ids[0]);
          if (parsed_response.id === null) {
            scheduler.deleteEvent(old_event.dhtmlx_id);
            scheduler.updateView();
          } else {
            let nuovo_id = old_event.oggetto + parsed_response.id.toString();
            scheduler.changeEventId(ids[0], nuovo_id);
            // la sede arriva come stringa ma deve essere intero perché è usata nei filtri
            old_event.sede = parseInt(old_event.sede);
            old_event.database_id = parsed_response.id;
            old_event.stato = parsed_response.stato;
            old_event.paziente_id = parsed_response.paziente_id;
            old_event.medico_id = parsed_response.medico_id;
            old_event.anticipi_da_pagare = parsed_response.anticipi_da_pagare;
            old_event.saldi_da_pagare = parsed_response.saldi_da_pagare;
            old_event.telefono_paziente = parsed_response.telefono_paziente;
            // eliminiamo un evento di troppo, gentile omaggio di dhtmlx
            scheduler.deleteEvent(old_event.dhtmlx_id);
            if (scheduler.getEvent(nuovo_id).color !== parsed_response.color) {
              scheduler.getEvent(nuovo_id).color = parsed_response.color;
              scheduler.updateView();
            }
            thisReact.props.enqueueSnackbar("App. salvato", {variant: 'success', autoHideDuration: 5000});
          }
        }
        return;
      }
      if (parsed_response.hasOwnProperty("non_field_errors")) {
        message = parsed_response["non_field_errors"].join(" - ");
      }
      const campi = ['studio', 'start_date', 'end_date', 'medico', 'cartella_clinica', 'text', 'testo_libero'];
      campi.forEach(function (campo, index) {
        if (parsed_response.hasOwnProperty(campo)) {
          message += "\n" + campo + ": " + parsed_response[campo];
        }
      });
      thisReact.props.enqueueSnackbar(message, {variant:  'error', autoHideDuration: 10000});
      let old_event = scheduler.getEvent(ids[0]);
      if (old_event !== undefined) {
        // eliminiamo un evento di troppo, gentile omaggio di dhtmlx
        if (old_event.dhtmlx_id !== undefined) scheduler.deleteEvent(old_event.dhtmlx_id);
      }
      scheduler.showLightbox(ids[0]);
    });

    this.nome_medico_finto_per_task = "";
    scheduler.attachEvent("onLoadEnd", this.onLoadEnd);

    scheduler.filter_week = this.filter_appointments;
    scheduler.filter_day = this.filter_appointments;
    scheduler.filter_dottori = this.filter_appointments;

    let search = window.location.search;
    let pars = new URLSearchParams(search);
    let evt_id = pars.get('evt_id');
    let evt_start_date = pars.get('evt_start_date');
    if (evt_id && evt_start_date) {
      scheduler.init(this.container.current, new Date(Date.parse(evt_start_date)), "dottori");
    } else {
      if (this.props.evt === undefined) {
        if (this.props.schedulerState === undefined) {
          scheduler.init(this.container.current, new Date(), "week");
        } else {
          scheduler.init(this.container.current, this.props.schedulerState.date, this.props.schedulerState.mode);
        }
      } else {
        if (this.props.schedulerState === undefined) {
          scheduler.init(this.container.current, this.props.evt.start_date, "week");
        } else {
          scheduler.init(this.container.current, this.props.schedulerState.date, this.props.schedulerState.mode);
        }
      }
    }
    scheduler.setLoadMode("day");
    scheduler.load(`${process.env.REACT_APP_API_URL}/dc/a`, "json");

    //localStorage.getItem('token')
    const dp = new window.dataProcessor(`${process.env.REACT_APP_API_URL}/dc/appuntamenti`);
    dp.init(scheduler);
    dp.setTransactionMode("REST", false);

    scheduler.showLightbox = (id) => {
      const evt = scheduler.getEvent(id);
      const sede_corrente_id = parseInt(localStorage.getItem("sede_corrente_id"));
      //passa l'evento al parent
      let options_pazienti = [];
      if (evt && evt.oggetto === 'task') {
        options_pazienti = [
          {
            label: evt.nome_paziente,
            value: {
              cartella_clinica: evt.cartella_clinica,
              id: evt.cartella_clinica,
              text: evt.nome_paziente
            }
          }
        ]
      }
/*      console.log("initScheduler showLightbox sede_corrente_id " + sede_corrente_id);
      console.log("initScheduler showLightbox this.state.medici_attivi_per_sede[sede_corrente_id] length " + this.state.medici_attivi_per_sede[sede_corrente_id].length);
      for( var i = 0; i < this.state.medici_attivi_per_sede[sede_corrente_id].length; i++) {
        console.log(JSON.stringify(this.state.medici_attivi_per_sede[sede_corrente_id][i]));
      } */
      //this.props.setEvent(evt, this.state.medici_attivi_per_sede[sede_corrente_id], sede_corrente_id, scheduler.getState(), options_pazienti);
      console.log("initScheduler " + JSON.stringify(evt));
      this.props.setEvent(evt, this.state.medici, this.state.sede_corrente_id, scheduler.getState(), options_pazienti);
      $("#tab_custom").html("");
      return false
    };

    if (this.props.evt !== undefined) {
      const ev = scheduler.getEvent(this.props.evt.id)
      for (var k in this.props.evt) ev[k] = this.props.evt[k]
      // scheduler vuole l'array stringa
      scheduler.getEvent(this.props.evt.id).json_attivita_appuntamento = JSON.stringify(scheduler.getEvent(this.props.evt.id).json_attivita_appuntamento)
      // https://docs.dhtmlx.com/scheduler/server_integration.html#triggeringdatasavingfromscript
      scheduler.updateEvent(ev) // la documentazione dice addEvent
    }
    $("#tab_custom").html("");
    $("#tab_custom").append($("#tab_ann"));
    $("#tab_custom").append($("#tab_task"));
    $("#tab_custom").append($("#tab_sedi"));
  }

  componentDidMount() {
    if (window.scheduler && window.scheduler.init) {
        this.reInitScheduler(window.scheduler)
        this.onLoadEnd()
    } else {
      this.ready.then(_ => {
        this.initScheduler(window.scheduler)
      })
    }
  }

  componentWillUnmount() {
    $("#tab_custom").html("");
  }

  componentDidUpdate() {
    try {
      scheduler.updateView();       
    } catch (e) {
      ;
    }
    
  }

  render() {
    return (
      <Box height="100%" width="100%">
      <div id="pdf_icon" style={{display:'none'}}><PdfIcon color="secondary"/></div>
      <div id="iniziale_icon" style={{display:'none'}}><ScheduleTwoToneIcon color="secondary"/></div>
      <div id="confermato_icon" style={{display:'none'}}><PriorityHighIcon color="secondary"/></div>
      <div id="effettuato_icon" style={{display:'none'}}><CheckCircleTwoToneIcon color="secondary"/></div>
      <div id="annullato_icon" style={{display:'none'}}><HighlightOffTwoToneIcon color="error"/></div>
      <div id="scheduler_here"  ref={this.container} className="widget-box dhx_cal_container" style={{width:"100%", height:900}}>
        <div className="dhx_cal_navline">
          <div className="dhx_cal_prev_button">&nbsp;</div>
          <div className="dhx_cal_next_button">&nbsp;</div>
          <div className="dhx_cal_today_button"></div>
          <div className="dhx_cal_date"></div>
          <div className="dhx_minical_icon" id="dhx_minical_icon" onClick={this.showMiniCal}>&nbsp;</div>
          <div className="dhx_cal_tab" name="week_tab" style={{left: 0 + 'px'}}></div>
          <div id="tab_dottori" className="dhx_cal_tab" name="dottori_tab" style={{left: 38 + 'px'}}></div>
          <div id="tab_task" className={this.state.isTaskOn ? 'dc_dhx_tab_custom dc_tab_task active' : 'dc_dhx_tab_custom dc_tab_task'}
                 aria-label="" role="button" aria-pressed="true" onClick={this.toggleTask}>TASK
          </div>
          <div id="tab_ann" className={this.state.isAnnullatiOn ? 'dc_dhx_tab_custom dc_tab_ann active' : 'dc_dhx_tab_custom dc_tab_ann'}
                 aria-label="" role="button" aria-pressed="true" onClick={this.toggleAnnullati}>ANN
          </div>
          <div id="tab_sedi" className='dc_dhx_tab_custom dc_tab_sedi active'
                aria-label="" role="button" aria-pressed="true">
                <select className="custom-select" onChange={this.cambiaSede.bind(this)}>
                  {this.state.sedi.map( (item) => {
                    if(this.state.sede_corrente_nome_breve === item.label) {
                      return (<option selected value={item.key}>{item.label}</option>)
                    } else {
                      return (<option value={item.key}>{item.label}</option>)
                    }
                  })}
                </select>
          </div>
        </div>
        <div className="dhx_cal_header"></div>
        <div className="dhx_cal_data"></div>
      </div>
      </Box>
      
    );
  }
}
export default withConfirm(withSnackbar(Scheduler));
